import React from 'react';

import PageHeader from '../components/PageHeader';
import TabSection from '../components/TabSection';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';

import policy from '../data/policy';

const TermsAndConditions = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Terms and Conditions'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'Terms and Conditions',
                        to: '/terms-and-conditions'
                    }
                ]}
            />

            <div className="container" style={{padding:'10px'}}>
                {/* <h3 className="c-dark">Privacy Policy</h3><br></br> */}
                {/* <p className="paragraph-big">Last updated on May 31, 2023</p> */}
                <br></br><br></br>
                
                <p>Please read these Terms and Conditions carefully before using CommuNN. 
                    By accessing or using our platform for community management, 
                    you agree to be bound by these Terms and Conditions.</p>
                <br></br>

                <h4 className="c-dark">1. Account Creation:</h4>
                <ul className="icon-list icon-list-1">
                    <li><span> To use Communn, you must create an account and provide accurate and complete information.</span></li>
                    <li><span> You are responsible for maintaining the confidentiality of your account credentials 
                        and are solely responsible for any activities carried out under your account.</span></li>
                    

                </ul>
                <br></br>

                <h4 className="c-dark">2. Community Creation and Management:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Administrators are required to undergo a Know Your Customer (KYC) verification process to create a community.</span></li>
                    <li><span>Members can join communities without verification, but administrators have the option to enforce identity verification if desired.</span></li>

                </ul>
                <br></br>

                {/* <h2 className="c-dark">Terms and Conditions:</h2> */}

               

                <h4 className="c-dark">3. Community Verification:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>As an administrator, you are responsible for creating genuine and legitimate communities.</span></li>
                    <li><span>We may require you to provide additional information or undergo a verification process to ensure the authenticity of your community.</span></li>
                    <li><span>Creating fraudulent or misleading communities is strictly prohibited.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">4. Member Payments:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span> As an administrator, you have the option to create paid communities w
                        here members are required to make payments.</span></li>
                        <li><span> It is your responsibility to provide valuable content, services, 
                            or benefits to justify the paid membership.</span></li>
                            <li><span> It is your responsibility to provide valuable content, services, 
                            or benefits to justify the paid membership.</span></li>
                            <li><span> Members should be informed about the payment terms, duration, and any refund policies.</span></li>
                </ul>
                <br></br>

                <h4 className="c-dark">5. Member Responsibility:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Members should exercise caution and conduct their own research before joining a paid community.</span></li>
                    <li><span>Communn does not guarantee the quality or effectiveness of any community or its offerings.</span></li>
                    <li><span>Members are responsible for assessing the value they receive from their membership and managing their own financial decisions.</span></li>
                    
                </ul>
                <br></br>

                <h4 className="c-dark">6. Refund Policy:</h4>
                <ul className="icon-list icon-list-2">
                <li><span> The refund policy for membership fees, subscriptions, or any other payments is allowed for 24 Hours from the initiation of the transaction.
                    </span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">7. Compliance with Laws:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Users of Communn must comply with all applicable laws and regulations related to community management, content creation, and member interactions.</span></li>
                    <li><span>Any illegal, fraudulent, or malicious activities are strictly prohibited.</span></li>
                    
                </ul>
                <br></br>

                <h4 className="c-dark">8. Prohibited Conduct:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>You agree not to use Communn for any unlawful, abusive, or fraudulent activities.</span></li>
                    <li><span>You are prohibited from engaging in any activity that disrupts or interferes with the proper 
                        functioning of the platform or compromises the privacy and security of other users.</span></li>
                   
                </ul>
                <br></br>

                <h4 className="c-dark">9. Content Ownership and Responsibility:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Administrators and members are solely responsible for the content they 
                        create, share, or post within communities.</span></li>
                        <li><span>You retain ownership of your content but grant Communn a non-exclusive, worldwide license to use, display, 
                            and distribute the content within the platform for community management purposes.</span></li>

                    
                </ul>
                <br></br>
                <h4 className="c-dark">10. Data Privacy and Security:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Communn takes data privacy and security seriously and implements appropriate measures to protect user information. 
                        Please refer to our Privacy Policy for detailed information.</span></li>
                        <li><span>You retain ownership of your content but grant Communn a non-exclusive, worldwide license to use, display, 
                            and distribute the content within the platform for community management purposes.</span></li>
                            
                    
                </ul>
                <br></br>
                <h4 className="c-dark">11. Limitation of Liability:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Communn is provided on an "as is" basis, and we do not guarantee the accuracy, availability, or reliability of the platform.</span></li>
                        <li><span>We are not liable for any damages, losses, or expenses incurred due to your use of or inability to use Communn.</span></li>
                            
                    
                </ul>
                <br></br>
                <h4 className="c-dark">12. Modification and Termination:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>We reserve the right to modify, suspend, or terminate Communn at any time without prior notice.</span></li>
                        <li><span>We may also terminate your account or restrict your access to the platform if you violate these Terms and Conditions.</span></li>
                            
                    
                </ul>
                <br></br>
                <h4 className="c-dark">13. Governing Law:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>These Terms and Conditions are governed by and construed in accordance with the law. Any disputes arising out of these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts.</span></li>

                </ul>
                <br></br>
                <p>
                By using Communn, you acknowledge that Communn acts as a platform to facilitate community management but does not guarantee the accuracy, 
                reliability, or legitimacy of any community or its administrators. You are responsible for 
                conducting your own due diligence and making informed decisions regarding your involvement with any community on the platform.</p>
                <p> 
                Failure to comply with these terms and conditions may result in the suspension or termination of your account or communities.
                </p>
                <p> 
                If you have any questions or concerns, please contact us at <a href='mailto:support@communn.com'>support@communn.com.</a>
                </p>
                <br></br><br></br>
                <p> 

                <b>Communn A venture of DIGITRII Solutions Pvt Ltd</b>
                </p>
                <br></br><br></br><br></br><br></br>


            </div>
            {/* page header - end */}

            {/* tab section - start */}
            {/* <TabSection data={policy} subHeading='Explore' className='tab-section-1' /> */}
            {/* tab section - end */}

           
        </>
    );
};

export default TermsAndConditions;
