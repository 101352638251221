import React from 'react';
import Typewriter from 'typewriter-effect';
import { useTypewriter, Cursor } from "react-simple-typewriter"

// components
import DownloadButton from '../components/DownloadButton';
import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';
import Button from '../components/Button';
import PricingSlider from '../components/PricingSlider';
import TestimonialSlider from '../components/TestimonialSlider';
import ScreenSlider from '../components/ScreenSlider';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import BlogSingle from '../components/BlogSingle';
import BackgroundAnimation from '../components/BackgroundAnimation';
import VideoLightBox from '../components/VideoLightBox';
import ImageSlider from '../components/ImageSlider';

// images

import background_image_2 from '../../assets/images/patterns/pattern-2.jpg';
import feature_phone_1 from '../../assets/images/feature-section-1-phone.png';
import feature_phone_2 from '../../assets/images/feature-section-2-phone.png';
import feature_image_2 from '../../assets/images/feature-section-2-img.jpg';
import Firstsection from '../../assets/images/sectionew2.png';
import Firstsection1 from "../../assets/images/Firstsectionnew.png";
import secondscreenimg from './../../assets/images/dashboard.png';
import Fourthscreen from "./../../assets/images/User-logs-influncer.png"
import ThirdScreen from "./../../assets/images/secondscreen.png"
import Six from "./../../assets/images/order-detail.png"


// data
import blog from '../data/blog';
import features from '../data/features';
import faq from '../data/faq';

const Index = () => {


    const [text] = useTypewriter({
        words: ['Brands',
            'Subscription', 'PAAS', 'Payments',
            'B2B', 'Ads Services','Transaction'],
        loop: {},
        typeSpeed: 100,
        deleteSpeed: 50,


    });


    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1 feature-section feature-section-0">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content">
                                    <h1 className="c-dark" style={{ fontSize: '35px', color: '#033F63' }}>"Up your Brands Influencer Marketing with our PaaS Platform" <br></br></h1>
                                    <p className="large c-grey" style={{}}>Chord Ai is a intelligent PAAS and B2B Robust API Provider which connects brands and influencer  using advance integration dashboard helps in revenue generation for brands and influencers 
                                    aims in seamless big data management and end to end management operations using ai and ml modules </p>
                                    {/* <DownloadButton color='c-white' /> */}
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-3"
                                    />
                                </div>
                                
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <div className="feature-section-image">
                                        <img src={Firstsection} className="image" alt="image" />
                                        <img src={Firstsection1} className="phone" alt="phone" />
                                        {/* <div className="background-pattern background-pattern-radius">
                                          <BackgroundAnimation /> *
                                            <div className="background-pattern-gradient" ></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1" id='features'>
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    heading='BUSINESS MODEL'
                                    subHeading='We Help You with the “ABC” of Influencer Marketing!'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            {features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}


            {/* video - start */}
            {/* <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-video"></i>
                                            <h5>our video</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Orions is a fast and secure app that was built for both Android and iOS platforms.</h1>
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started"
                                        type="button-1"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img} width='471' height='472' alt="drop-shadow" />

                                        <div className="play">
                                            <VideoLightBox URL='https://www.youtube.com/watch?v=WIl5F5rM5wQ' />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation />
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* video - end */}
            {/* feature section - start */}
            <div className="feature-section feature-section-1 feature-section-spacing-2">
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <div className="feature-section-image">
                                    {/* <img src={feature_image_2} className="image" alt="feature-fore" /> */}
                                    <img src={secondscreenimg} className="phone" alt="phone" />
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        // icon='las la-cog'
                                        // heading='app features'
                                        subHeading='Easily Search for Influencers with our Influencer Discovery Tool'

                                    />
                                    <p className="c-grey" style={{textAlign:'justify'}}>
                                    We recommend a list of influencers based on your brand (personality and vision)
                                     and the campaign (audience, location, and social media channels).

                                        </p>
                                    <div className="icon-text-1-group">
                                        <div className="icon-text-1">
                                            {/* <i className="las la-comments"></i>
                                            <div>
                                                <h4 className="c-dark">Video calls</h4>
                                                <p className="c-grey">He saw lesser whales man air. Seasons void fly replenish man divided open fifth own fruitful.</p>
                                            </div> */}
                                        </div>
                                        <div className="icon-text-1">
                                            {/* <i className="las la-headset"></i>
                                            <div>
                                                <h4 className="c-dark">Private groups</h4>
                                                <p className="c-grey">Give whales creeping sixth. Blessed itself created dry they're firmament face whose face lesser spirit day dry.</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-3"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* feature section - start */}
            <div className="feature-section3 feature-section-0 feature-section-spacing-1 third_desktop" >
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        // icon='las la-cog'
                                        // heading='app features'
                                        subHeading='Evaluate Influencers with our Influencer Report'
                                    />
                                    <p className="c-grey" style={{textAlign:'justify'}}>
                                    We help you know the quality of your chosen influencers through a report that shows
                                     numbers such as Consistency and Overall Starbuzz Score.</p>
                                    <div className="icon-text-group">
                                        <div className="icon-text">
                                            {/* <i className="las la-server"></i>
                                            <h4 className="c-dark">Unlimited Storage</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p> */}
                                        </div>
                                        {/* <div className="icon-text">
                                            <i className="las la-user-shield"></i>
                                            <h4 className="c-dark">Data Protection</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p>
                                        </div> */}
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-2"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                     
                                    <img src={ThirdScreen} className="phone" alt="phone" />


                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                    {/* <img src={feature_image_1} className="image" alt="feature-fore" /> */}
                                    {/* <img src={secondscreenimg} className="phone" alt="phone" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}



            {/* feature section - start */}
            <div className="feature-section4 feature-section-0 feature-section-spacing-2 third_mobile" >
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="feature-section-c   ontent">
                                    <SectionHeading
                                        // icon='las la-cog'
                                        // heading='app features'
                                        subHeading='Track your Brand Campaigns with our Live Campaign Tracking' 
                                        />
                                        <p className="c-grey" style={{marginTop:'20px', textAlign:'justify'}}>
                                        Our platform lets you collaborate for a brand campaign, launch it, 
                                        and ensure it stays on track from start to finish and reaches its set goal.</p>
                                    <div className="icon-text-group">
                                        <div className="icon-text">
                                            {/* <i className="las la-server"></i>
                                            <h4 className="c-dark">Unlimited Storage</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p> */}
                                        </div>
                                        <div className="icon-text">
                                            {/* <i className="las la-user-shield"></i>
                                            <h4 className="c-dark">Data Protection</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p> */}
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-2"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                     
                                    <img src={ThirdScreen} className="phone" alt="phone" />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* feature section - start */}
            <div className="feature-section feature-section-1 feature-section-spacing-2">
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <div className="feature-section-image">

                                    {/* <img src={feature_image_2} className="image" alt="feature-fore" />  */}
                                    <img src={Fourthscreen} className="phone" alt="phone" />

                                </div>

                            </div>

                            <div className="col-lg-6 offset-lg-1 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        // icon='las la-cog'
                                        // heading='app features'
                                        subHeading='Get Campaign Reports to Set Yourself up for Growth'
                                    />
                                    <p className="c-grey" style={{textAlign:'justify'}}>We track and share a report of campaign metrics such as ROI,
                                     budget spent, and CPM that will enable you to improve your influencer marketing.</p>
                                    <div className="icon-text-1-group">
                                        <div className="icon-text-1">
                                            {/* <i className="las la-comments"></i>
                                            <div>
                                                <h4 className="c-dark">Video calls</h4>
                                                <p className="c-grey">He saw lesser whales man air. Seasons void fly replenish man divided open fifth own fruitful.</p>
                                            </div> */}
                                        </div>
                                        <div className="icon-text-1">
                                            {/* <i className="las la-headset"></i>
                                            <div>
                                                <h4 className="c-dark">Private groups</h4>
                                                <p className="c-grey">Give whales creeping sixth. Blessed itself created dry they're firmament face whose face lesser spirit day dry.</p>
                                            </div> */}
                                        </div>
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-3"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}

            {/* feature section - start */}
            <div className="feature-section2 feature-section-0 feature-section-spacing-1 "  >
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="row gx-5">
                            <div className="col-lg-7 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="feature-section-content">
                                    <SectionHeading
                                        // icon='las la-cog'
                                        // heading='app features'
                                        subHeading='Track your Brand Campaigns with our Live Campaign Tracking'
                                    />
                                    <p className="c-grey" style={{textAlign:'justify'}}>
                                    Our platform lets you collaborate for a brand campaign, launch it, and 
                                    ensure it stays on track from start to finish and reaches its set goal.</p>
                                    <div className="icon-text-group">
                                        <div className="icon-text">
                                            {/* <i className="las la-server"></i>
                                            <h4 className="c-dark">Unlimited Storage</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p> */}
                                        </div>
                                        {/* <div className="icon-text">
                                            <i className="las la-user-shield"></i>
                                            <h4 className="c-dark">Data Protection</h4>
                                            <p className="c-grey">Heat multiply is second divided fowl there isn't man cattle.</p>
                                        </div> */}
                                    </div>
                                    <Button
                                        to="/contact"
                                        content="Get Started Free"
                                        type="button-2"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="feature-section-image">
                                    {/* <img src={feature_image_1} className="image" alt="feature-fore" /> */}
                                    <img src={Six} className="phone" alt="phone" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* feature section - end */}


             {/* screen section - start */}
             <div className="screen-section" >
                <div className="screen-section-wrapper">
                    <ScreenSlider />

                </div>
            </div>

            {/* pricing section - start */}
            {/* <div className="pricing-section">
                <div className="pricing-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-tags'
                                    heading='our app rates'
                                    subHeading='Pricing plans for you.'
                                    additionalClasses='center c-white'
                                    mainHeadingClass='c-white'
                                    subHeadingClass='c-white'
                                />
                            </div>
                        </div>
                        <PricingSlider />
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{ backgroundImage: `url(${background_image_1})` }}></div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* pricing section - end */}

            {/* testimonial section - start */}
            {/* <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-comments'
                                    heading='feedbacks'
                                    subHeading='What people are talking about.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <TestimonialSlider />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* testimonial section - end */}


            {/* faq section - start */}
            {/* <div className="faq-section" style={{padding:'50px'}}>
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    // icon='las la-file-alt'
                                    // heading='discover'
                                    subHeading='Frequently Asked Questions'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-md-8 col-10">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                                className={`accordion-button ${key !== 1 ? 'collapsed' : ''}`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#faq-collapse-${key}`}
                                                                aria-expanded={key === 1 ? 'true' : 'false'}
                                                                aria-controls={`faq-collapse-${key}`}>
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div
                                                            id={`faq-collapse-${key}`}
                                                            className={`accordion-collapse collapse ${key === 1 ? 'show' : ''}`}
                                                            aria-labelledby={`faq-${key}`}
                                                            data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* faq section - end */}

            
            {/* <div className="screen-section">
                <div className="screen-section-wrapper">
                    <ScreenSlider />
                    screen section bottom - start 
                    <div className="screen-section-bottom">
                        <div className="screen-section-bottom-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-0 col-10 offset-1">
                                        <h1 className="c-white">Download from both Android and iOS</h1>
                                        <h4 className="c-white">
                                            <i className="fas fa-smile" style={{ marginRight: '1rem' }}></i>
                                            3 Million users and counting!
                                        </h4>
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-10 offset-1">
                                        <DownloadButton groupType='download-button-1-group' buttonType='download-button-1' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="background-pattern background-pattern-2">
                    <BackgroundAnimation />
                    <div className="background-pattern-gradient"></div>
                    <div className="background-pattern-bottom">
                        <div className="image" style={{ backgroundImage: `url(${background_image_2})` }}></div>
                    </div>
                </div>
            </div> */}
            {/* screem section - end

            {/* blog section - start */}
            {/* <div className="blog-section">
                <div className="blog-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-bullhorn'
                                    heading='recent news'
                                    subHeading='Read latest news from our blog.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                        <div className="row gx-5">
                            {
                                blog.slice(0, 3).map((element, key) => {
                                    return (
                                        <div key={key} className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                            <BlogSingle element={element} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div> */}
            {/* blog section - end */}

            {/* cta section - start */}
            {/* <div className="cta-section">
                <div className="cta-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-cloud-download-alt'
                                    heading='Download Orions'
                                    subHeading='Join Orions and get 20% off on your selected plan.'
                                    additionalClasses='center width-71'
                                />
                                <DownloadButton color='c-white' />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* cta section - end */}

            {/* contact form section - start */}
            {/* <div className="contact-form-section">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                
                                <ContactForm1 />
                                
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                               
                                <ContactForm2 />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* contact form section - end */}

            {/* instagram - start */}
            {/* <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider />
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div> */}
            {/* instagram - end */}
        </>
    );
};

export default Index;
