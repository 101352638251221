import React from 'react';

import PageHeader from '../components/PageHeader';
import TabSection from '../components/TabSection';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';

import policy from '../data/policy';

const Risks = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Risks'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'Risks',
                        to: '/risks'
                    }
                ]}
            />

            <div className="container" style={{padding:'10px'}}>
                {/* <h3 className="c-dark">Privacy Policy</h3><br></br> */}
                {/* <p className="paragraph-big">Last updated on May 31, 2023</p> */}
                <br></br><br></br>
                <p>
                While using Communn for community management, it's important to be aware of certain risks. 
                Although we strive to provide a secure and reliable platform, please consider the following:</p>
                <br></br>

                <h4 className="c-dark">1. Information Security:</h4>
                <ul className="icon-list icon-list-1">
                    <li><span> While we implement robust security measures to protect user data, no online platform can guarantee 100% security.</span></li>
                    <li><span> There is a minimal risk of unauthorized access, data breaches, or other security vulnerabilities 
                        that could compromise the confidentiality of your information.</span></li>

      

                </ul>
                <br></br>

                <h4 className="c-dark">2. User Conduct:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span> Communn hosts various communities with diverse members and administrators.</span></li>
                    <li><span>We cannot control or monitor the actions of individuals within communities, 
                        and there is a risk of encountering inappropriate or offensive content or interactions.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">3. Third-Party Links and Content:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span> Communn may contain links to third-party websites or content.</span></li>
                    <li><span>We are not responsible for the accuracy, reliability, or safety of such external resources 
                        and cannot guarantee their appropriateness or security.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">4. Technical Issues:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span> While we strive for uninterrupted platform availability, Communn may experience technical glitches, 
                        downtime, or other technical issues.</span></li>
                        <li><span> These issues could temporarily disrupt your ability to access or manage your communities.</span></li>
                </ul>
                <br></br>

                <h4 className="c-dark">5. Changes to Terms and Features:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Communn may update its Terms and Conditions and features from time to time.</span></li>
                    <li><span>It's important to stay informed about any changes that may affect your rights, obligations, 
                        or the functionality of the platform.</span></li>
                    
                </ul>
                <br></br>

                
                <p>
                Additionally, please note that administrators can create paid communities on CommuNN. 
                It is crucial to ensure that any paid community you join is genuine, as it will 
                help you avoid wasting money on communities that do not meet your expectations.</p>
                <p> 
                We encourage users to exercise caution, adhere to our Community Guidelines, and report 
                any concerns or incidents to our support team. By being vigilant and proactive, we can work together
                 to mitigate potential risks and create a safer community environment.
                </p>

                <br></br><br></br>
                <p> 

                <b>Communn A venture of DIGITRII Solutions Pvt Ltd</b>
                </p>
                <br></br><br></br><br></br><br></br>


            </div>
            {/* page header - end */}

            {/* tab section - start */}
            {/* <TabSection data={policy} subHeading='Explore' className='tab-section-1' /> */}
            {/* tab section - end */}

           
        </>
    );
};

export default Risks;
