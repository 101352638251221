import React from 'react';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/Logo-Full.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const Footer = () => {
    return (
        <footer className="footer" style={{backgroundColor:'#f7f7f7'}}>
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-row">
                                <div className="footer-detail">
                                    <Link to="#">
                                        <img src={logo} alt="footer-logo"  width={200} height={60}/>
                                    </Link>
                                    <p className="c-grey-1">Chord Ai is a intelligent PAAS and B2B Robust API Provider which connects brands and influencer.</p>
                                    <div className="links">
                                        <a className="link-underline" href="mailto:info@thechordai.com">
                                        <i class='fas fa-envelope' style={{color:'#033F63', fontSize:'20px', marginRight:'5px'}}></i>
                                            <span>info@thechordai.com
</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="footer-list footer-social social-gradient">
                                    <h6>Follow Us</h6>
                                    <ul>
                                        <li className="twitter">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-twitter"></i>
                                                <span>Twitter</span>
                                            </Link>
                                        </li>
                                        <li className="facebook">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-facebook"></i>
                                                <span>Facebook</span>
                                            </Link>
                                        </li>
                                        <li className="linkedin">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-linkedin-in"></i>
                                                <span>Linkedin</span>
                                            </Link>
                                        </li>
                                        <li className="youtube">
                                            <Link to="#" className="link-underline">
                                                <i className="fab fa-youtube"></i>
                                                <span>Youtube</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Quick Links</h6>
                                    <ul>
                                        <li>
                                            <Link to="/about" className="link-underline">
                                                <span>About</span>
                                            </Link>
                                        </li>
                                        
                                        <li>
                                            <Link to="/" className="link-underline">
                                                <span>Products</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="link-underline">
                                                <span>How It Works</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="contact" className="link-underline">
                                                <span>Contact Us</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Help Links</h6>
                                    <ul>
                                        <li>
                                            {/* <Link to="pricing" className="link-underline">
                                                <span>Pricing</span>
                                            </Link> */}
                                        </li>
                                        <li>
                                            <Link to="terms-and-conditions" className="link-underline">
                                                <span>Terms & Conditions</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="privacy-policy" className="link-underline">
                                                <span>Privacy Policy</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="risks" className="link-underline">
                                                <span>Risks</span>
                                            </Link>
                                            
                                        </li>
                                        <li>
                                            <Link to="refund-policy" className="link-underline">
                                                <span>Refund Policy</span>
                                            </Link>
                                        </li>
                                        
                                        
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-copyright c-grey-1">
                                {/* <h6 style={{textAlign:'center'}}>© 2023 Communn. All Rights Reserved</h6> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="footer-pattern " style={{backgroundImage: `url(${background_image_1})`, paddingBottom:'20px'}}>
               
                </div> */}
            </div>
            <h6 style={{textAlign:'center', paddingBottom:'20px'}}> © 2024 ChordAi. All Rights Reserved | Tech Trillion Technologies Private Limited.
</h6>
        </footer>
    );
};

export default Footer;
