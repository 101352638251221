import React from 'react';

import PageHeader from '../components/PageHeader';
import TabSection from '../components/TabSection';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';

import policy from '../data/policy';

const PrivacyPolicy = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Privacy Policy.'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'Privacy Policy',
                        to: '/privacy-policy'
                    }
                ]}
            />

            <div className="container" style={{padding:'10px'}}>
                {/* <h3 className="c-dark">Privacy Policy</h3><br></br> */}
                {/* <p className="paragraph-big">Last updated on May 31, 2023</p> */}
                <br></br><br></br>
                
                <p>At Communn, we are committed to protecting the privacy and security of our users' information.
                    This Privacy Policy outlines how we collect, use,
                    and safeguard the data you provide while using our platform for community management.</p>
                <br></br>

                <h4 className="c-dark">1. Information Collection:</h4>
                <ul className="icon-list icon-list-1">
                    <li><span> During the registration process, administrators are required to undergo a Know Your
                        Customer (KYC) verification to create a community.</span></li>
                    <li><span> Members have the option to verify their identity, but it is not mandatory.</span></li>
                    <li><span> We collect personal information, such as names and email addresses, to facilitate community
                        management and communication.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">2. Use of Information:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>The information provided by administrators and members is used to establish and manage communities,
                        facilitate communication, and enhance the user experience.</span></li>
                    <li><span>We may use personal information for verifying identities, processing payments, and delivering relevant community updates.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">3. Data Security:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>We employ robust security measures to protect the data from unauthorized access, disclosure, and misuse.</span></li>
                    <li><span>Each community's data is stored separately and is inaccessible to other communities.</span></li>
                    <li><span>Member data is securely stored and is not shared between communities.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">4. Multiple Communities:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span> Members have the freedom to join multiple communities and create their own communities, 
                        fostering a diverse and engaging environment.</span></li>
                </ul>
                <br></br>

                <h4 className="c-dark">5. Data Retention:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>We retain personal information for as long as necessary to fulfill the purposes 
                        outlined in this Privacy Policy, or as required by law.</span></li>
                    
                </ul>
                <br></br>

                <h4 className="c-dark">6. Third-Party Services:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>We may use trusted third-party services to provide additional functionality or process payments. 
                        These services may have their own privacy policies, and we encourage you to review them.</span></li>

                </ul>
                <br></br>

                <h4 className="c-dark">7. Your Rights:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Administrators and members have the right to access, update, and delete their personal information as permitted by law.</span></li>
                    <li><span>Members can choose to opt-out of certain data processing activities and manage their privacy settings.</span></li>
                    
                </ul>
                <br></br>

                <h4 className="c-dark">8. Children's Privacy:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Communn is intended for use by individuals aged 16 and above. 
                        We do not knowingly collect personal information from children.</span></li>
                   
                </ul>
                <br></br>

                <h4 className="c-dark">9. Changes to the Privacy Policy:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>We may update this Privacy Policy periodically. Any changes will be posted on our website, 
                        and your continued use of Communn constitutes your acceptance of the updated policy.</span></li>
                    
                </ul>
                <p>
                Please review this Privacy Policy to understand how we handle your information.</p>
                <p> 
                If you have any questions or concerns, please contact us at <a href='mailto:support@communn.com'>support@communn.com.</a>
                </p>
                <br></br><br></br>
                <p> 

                <b>Communn A venture of DIGITRII Solutions Pvt Ltd</b>
                </p>
                <br></br><br></br><br></br><br></br>


            </div>
            {/* page header - end */}

            {/* tab section - start */}
            {/* <TabSection data={policy} subHeading='Explore' className='tab-section-1' /> */}
            {/* tab section - end */}

            {/* instagram - start */}
            {/* <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div> */}
            {/* instagram - end */}
        </>
    );
};

export default PrivacyPolicy;
