const faq = [
    {
        question: 'Is there a maximum limit on the number of members allowed in a community?',
        answer: 'No, there is no maximum limit on the number of members for your community. You can grow your community as large as you want and accommodate a diverse range of members.'
    },
    {
        question: 'What are the different member roles available, and how do they function?',
        answer: 'We offer member roles such as admins and regular members. Admins have full control and can manage all aspects of the community.'
    },
    {
        question: 'What are the differences between a public and private community?',
        answer: 'In a public community, anyone can join and participate freely. In a private community, membership requires approval from the admin, ensuring a more exclusive and controlled environment.'
    },
    {
        question: 'How can I collect subscriptions from community members, and what are the payment options available?',
        answer: 'Our platform offers built-in subscription management tools. You can set up subscription plans, define pricing, and choose from various payment options, such as credit/debit cards, or other secure payment gateways.'
    },
    {
        question: 'Is there a limit to the number of communities I can create?',
        answer: 'No, there is no limit to the number of communities you can create. You can create multiple communities based on different interests, topics, or target audiences.'
    },
    // {
    //     question: 'How can I promote my community and attract more members?',
    //     answer: 'We provide various promotional tools and features, such as community invitations, social media sharing. Additionally, you can utilize content marketing, collaborations, and online advertisements to attract more members to your community.'
    // }
];

export default faq;