const features = [
    {
        icon: 'las la-comments',
        heading: 'Brands Commission',
        excerpt: 'Brands Commission Enabled on boarding tech brands.',
        
    },
    {
        icon: 'las la-headset',
        heading: 'Subscription Service',
        excerpt: 'Brand and Influencers Platform Saas.',
        
    },
    {
        icon: 'las la-photo-video',
        heading: 'PAAS',
        excerpt: 'Will provide Indigenous Platform for Agencies.',
        
    },
    {
        icon: 'las la-user-lock',
        heading: 'B2B Collab!',
        excerpt: 'We Work on B2B Collab! With brand & Influencers.',
       
    },
    {
        icon: 'las la-server ',
        heading: 'Ads Services',
        excerpt: 'Lead generation, views, apply, submit, purchases, clicks.',
        
       
    },
    {
        icon: 'las la-photo-video',
        heading: 'Transaction ',
        excerpt: 'We charge 2% on settlement of every cashback provided to user.',        
    }
];

export default features;