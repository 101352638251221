import React from 'react';

import PageHeader from '../components/PageHeader';
import TabSection from '../components/TabSection';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';

import policy from '../data/policy';

const RefundPolicy = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Refund Policy.'
                pages={[
                    {
                        title: 'Home',
                        to: '/'
                    },
                    {
                        title: 'Refund Policy',
                        to: '/refund-policy'
                    }
                ]}
            />

            <div className="container" style={{ padding: '10px' }}>
                {/* <h3 className="c-dark">Privacy Policy</h3><br></br> */}
                {/* <p className="paragraph-big">Last updated on May 31, 2023</p> */}
                <br></br><br></br>

                <p>At Communn, we strive to provide a satisfying experience for our users. However, in certain situations,
                    refunds may be applicable. Please review our refund policy below:
                </p>
                <br></br>

                <h4 className="c-dark">1. Business/Merchants/Vendors Refund Policy:</h4>
                <ul className="icon-list icon-list-1">
                    <li><span> Each Business/Merchants/Vendors on Communn is managed by its respective owner/administrator.
                    </span></li>
                    <li><span> The refund policy for membership fees, subscriptions, or any other payments is allowed for 24 Hours from the initiation of the transaction.
                    </span></li>
                   

                </ul>
                <br></br>

                <h4 className="c-dark">2. Platform Fee:</h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Communn collects a percentage of transaction fees for the services provided. Which is allowed for 24 Hours from the initiation of the transaction.
                    </span></li>
                    <li><span>This platform fee is Refundable and which is allowed for 24 Hours from the initiation of the transaction..
                    </span></li>

                </ul>
                <br></br>

                {/* <h4 className="c-dark">3. Communication and Disputes:
                </h4>
                <ul className="icon-list icon-list-2">
                    <li><span>Any communication, disputes, or requests for refunds should be directed to the Business/Merchants/Vendors</span></li>
                    <li><span>Communn will not be involved in the refund process or mediate disputes between community members and community owners.
                    </span></li>


                </ul>
                <br></br> */}


                {/* <p>

                    Please note that Communn is not responsible for any refund obligations or decisions made
                    by community owners. It is the community owner's sole responsibility to provide accurate
                    refund information and process refunds according to their refund policy.
                </p>
                <br></br> */}
                {/* <p>
                    We recommend reviewing the refund policy of a community before joining or making any payments
                    to ensure you understand the terms and conditions regarding refunds.
                </p> */}
                <br></br><br></br>
                <p> 

                <b>Communn A venture of DIGITRII Solutions Pvt Ltd</b>
                </p>
                <br></br><br></br><br></br><br></br>


            </div>
            {/* page header - end */}

            {/* tab section - start */}
            {/* <TabSection data={policy} subHeading='Explore' className='tab-section-1' /> */}
            {/* tab section - end */}

            {/* instagram - start */}
            {/* <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div> */}
            {/* instagram - end */}
        </>
    );
};

export default RefundPolicy;
